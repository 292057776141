// MUI Components
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

// Google Map API
import { GoogleMap, Marker } from '@react-google-maps/api';

function FirstMap({ dealerGeoLocations }) {
    const zoom = 9;
    const center = {
        lat: 33.4484,
        lng: -112.0740
    }
    const mapStyle = {
        width: '100%',
        height: '400px'
    }

    return (
        <Card className='bg-toyota-red w-full xs:max-w-sm' sx={{ heigth: 800 }}>
            <CardContent id='first-map' className='xs:max-w-sm'>
                <GoogleMap
                    mapContainerClassName='xs:max-w-sm'
                    mapContainerStyle={mapStyle}
                    center={center}
                    zoom={zoom}
                >
                    {dealerGeoLocations.map((dealer, index) => {
                        return (
                            <Marker
                                key={index}
                                position={dealer.location}
                            />
                        );
                    })}
                </GoogleMap>
            </CardContent>
        </Card>
    );
}

export default FirstMap;