// MUI Components
import Paper from '@mui/material/Paper';

// My Components
import Title from './Title/Title';

function HeaderContainer() {

    return (
        <Paper component='header' className='mt-1 mb-5 flex justify-center content-center justify-items-center xs:mb-12' sx={{ bgcolor: 'transparent' }} elevation={0} square >
            <Title />
        </Paper>
    );
}

export default HeaderContainer