// MUI Components
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Link from '@mui/material/Link';

function DealerList({ dealerList }) {

    return (
        <Card className='flex flex-auto flex-col justify-center items-center content-center w-fit xs:mb-0 sm:mb-0 h-fit xs:m-2' sx={{ bgcolor: 'transparent' }}>
            <CardContent className='overflow-scroll p-0' sx={{ maxHeight: 440 }}>
                {dealerList.map((dealer, index) => {

                    return (
                        <Card key={index} className='bg-toyota-red mb-1'>
                            <CardContent>
                                <Box className='mb-1'>
                                    <Typography className='font-toyota text-xl font-bold' sx={{ color: 'white' }}>
                                        {index + 1}. {dealer.dealerInfo.name}
                                    </Typography>
                                    <Typography className='font-toyota' sx={{ color: 'white' }}>
                                        {dealer.dealerInfo.address}
                                    </Typography>
                                </Box>
                                <Box className='flex flex-auto justify-between content-center'>
                                    <Box className='flex flex-auto flex-col content-center justify-center'>
                                        <Typography className='font-toyota font-bold' sx={{ color: 'black' }}>
                                            Phone
                                        </Typography>
                                        <Typography className='font-toyota' sx={{ color: 'white' }}>
                                            <Link id={`${dealer.dealerInfo.name}-phone`} className='font-toyota text-sm' href={`tel:${dealer.dealerInfo.phone}`} underline='none' sx={{ color: 'white' }} >
                                                {dealer.dealerInfo.phoneStr}
                                            </Link>
                                        </Typography>
                                    </Box>
                                    <Box className='flex flex-auto flex-col content-center justify-center'>
                                        <Typography className='font-toyota font-bold' sx={{ color: 'black' }}>
                                            Hours
                                        </Typography>
                                        <Typography className='font-toyota' sx={{ color: 'white' }}>
                                            {dealer.dealerInfo.hours}
                                        </Typography>
                                    </Box>
                                </Box>
                            </CardContent>
                            <CardActions>
                                <Box>
                                    <ButtonGroup color='error' disableElevation variant='contained'>
                                        <Button className='font-toyota text-sm hover:bg-toyota-dark py-1' sx={{ color: 'white', bgcolor: 'black' }}>
                                            <Link id={dealer.dealerInfo.serviceID} className='font-toyota text-sm' href={dealer.dealerInfo.serviceLink} underline='none' target='_blank' rel='noopener' sx={{ color: 'white' }} >
                                                Schedule Service
                                            </Link>
                                        </Button>
                                        <Button className='font-toyota text-sm hover:bg-toyota-dark py-1' sx={{ color: 'white', bgcolor: 'black' }}>
                                            <Link id={dealer.dealerInfo.contactID} href={dealer.dealerInfo.contactLink} className='font-toyota text-sm' underline='none' target='_blank' rel='noopener' sx={{ color: 'white' }} >
                                                Contact Dealer
                                            </Link>
                                        </Button>
                                        <Button className='font-toyota text-sm hover:bg-toyota-dark py-1' sx={{ color: 'white', bgcolor: 'black' }}>
                                            <Link id={dealer.dealerInfo.websiteID} href={dealer.dealerInfo.websiteLink} className='font-toyota text-sm' underline='none' target='_blank' rel='noopener' sx={{ color: 'white' }} >
                                                Visit Website
                                            </Link>
                                        </Button>
                                    </ButtonGroup>
                                </Box>
                            </CardActions>
                        </Card>
                    );
                })}
            </CardContent>
        </Card>
    );
}

export default DealerList;