// MUI Components
import Box from '@mui/material/Box';

function Title() {
    return (
        <Box className='flex justify-center content-center justify-items-center mb-12 xs:mb-1 sm:mb-1'>
            <Box className='flex flex-wrap justify-center justify-items-center content-center' >
                <img className='center justify-self-center max-w-sm max-h-24' src='https://email-mrktng-images.s3.us-east-1.amazonaws.com/images/landing-page/newer-logo.jpg' alt='Valley Toyota Dealers Logo' />
            </Box>
        </Box>
    );
}

export default Title;