// My Components
import ActionCall from "./ActionCall/ActionCall";
import PromoContainer from "./PromoArea/PromoContainer";
import AboutVTD from "./AboutVTD/AboutVTD";
import MapConatiner from "./MapContainer/MapContainer";

function MainContainer() {

    return (
        <main className='flex flex-col justify-center items-center mb-10'>
            <PromoContainer />
            <ActionCall />
            <AboutVTD />
            <MapConatiner />
        </main>
    );
}

export default MainContainer;