// MUI Components
import Box from '@mui/material/Box';

// My Components
import PromoAreaOne from './PromoAreaOne/PromoAreaOne';
import PromoAreaTwo from './PromoAreaTwo/PromoAreaTwo';

function PromoContainer() {

    return (
        <Box className='flex flex-auto flex-col items-center mb-96 xs:mb-10'>
            <PromoAreaOne />
            <PromoAreaTwo />
        </Box>
    );
}

export default PromoContainer;