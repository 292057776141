import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import CssBaseline from '@mui/material/CssBaseline';

// Theme Provider for MUI
import { createTheme, ThemeProvider } from "@mui/material/styles";

// Helps apply TailwindCSS to MUI components
import GlobalCssPriorityProvider from './Utils/GlobalCssPriorityProvider/GlobalCssPriorityProvider';
import PlainCssPriorityProvider from './Utils/PlainCssPriorityProvider/PlainCssPriorityProvider';

const theme = createTheme({
  palette: {
    background: {
      default: "#000000"
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <GlobalCssPriorityProvider>
    <PlainCssPriorityProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </PlainCssPriorityProvider>
  </GlobalCssPriorityProvider>
);