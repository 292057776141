function mergeSortByFirstAttr(arr) {
    // Make sure array isnt empty and can be sorted
    if (arr.length <= 1) {
        return arr;
    }

    // Get middle value to divide array in half
    const mid = Math.floor(arr.length / 2);
    // Gets left half of array
    const left = arr.slice(0, mid);
    // Gets right half of array
    const right = arr.slice(mid);

    // Function call to sort the array
    return mergeByFirstAttr(mergeSortByFirstAttr(left), mergeSortByFirstAttr(right));
}

function mergeByFirstAttr(left, right) {
    // empty array to add sorted objects
    let result = [];
    // counter for left side of array
    let i = 0;
    // counter for right side of array
    let j = 0;


    while (i < left.length && j < right.length) {
        // if the left objects first attribute is less than the right then add left object to array
        if (left[i][Object.keys(left[i])[0]] < right[j][Object.keys(right[j])[0]]) {
            result.push(left[i]);
            i++;
        } else {
            // if the right objects first attribute is less than the left then add right object to array
            result.push(right[j]);
            j++;
        }
    }

    // Merges sorted array
    return result.concat(left.slice(i)).concat(right.slice(j));
}

export default mergeSortByFirstAttr