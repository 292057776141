// MUI Components
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

// Sorting Function
import mergeSortByFirstAttr from '../../../../Utils/SortingAlgo/SortingAlgo';

// React Hook
import { useRef } from 'react';

function MapForm({ zipCode, setZipCode, setShowMap, setDealerList, setMapInfo, zipCodes, dealerGeoLocations }) {
    const inputRef = useRef(null);

    const submitHandler = async (e) => {
        e.preventDefault();

        if(inputRef.current) {
            inputRef.current.blur();
        }

        try {
            const geocoder = new window.google.maps.Geocoder();

            const coordinatesList = await geocoder.geocode({ address: zipCode }, geocodeCallback);

            // Callback function for Geocoder
            function geocodeCallback(response, status) {
                if (status !== "OK") {
                    return;
                }
            }

            // Seperates needed data from extra data provided by API
            const coordinatesObject = coordinatesList.results[0].geometry.viewport;

            // Gets the key names from the viewport object from the repsonse
            const [attr1, attr2] = Object.keys(coordinatesObject);

            // Stores coordinate values
            const coord1 = coordinatesObject[attr1].lo;
            const coord2 = coordinatesObject[attr2].lo;

            // To hold user lat and lng
            let latitude;
            let longitude;

            // If the coordinate1 is the latitude set it, else set coordinate1 as longitude
            if (coord1 > coord2) {
                latitude = coord1;
                longitude = coord2;
            } else {
                latitude = coord2;
                longitude = coord1;
            }

            // Gets specific latitude and longitude coordinates values and stores them in a new object
            const userCoordinates = {
                lat: latitude,
                lng: longitude
            }

            // instantiate Distance Matrix service
            const service = new window.google.maps.DistanceMatrixService();

            const matrixOptions = {
                // dealer locations
                origins: [zipCode],
                // customer address
                destinations: zipCodes,
                travelMode: 'DRIVING',
                unitSystem: window.google.maps.UnitSystem.IMPERIAL
            }
            
            // Call Distance Matrix service
            service.getDistanceMatrix(matrixOptions, callback);

            // Callback function used to process Distance Matrix response
            function callback(response, status) {
                if (status !== "OK") {
                    return;
                }

                // Pulls distance data from api response
                const distanceObjects = response.rows[0].elements;

                // Grabs the distances in miles from response and splits number from the units and maps into new array
                const distances = distanceObjects.map((dataObject, i) => { return dataObject.distance.text.split(' ') });

                // Empty array to hold unordered list of distance from user to dealer
                const unorderedList = [];
                // Empty array to hold object containing distance to dealership and zipcode
                const zipDistance = [];

                for (let i = 0; i < distances.length; i++) {
                    // Adds numeric value for distance to unsorted array
                    unorderedList.push(parseInt(distances[i][0]));
                }

                for (let i = 0; i < zipCodes.length; i++) {
                    // adds distance and zipcode object into array
                    zipDistance.push({ distance: unorderedList[i], zipcode: zipCodes[i] });
                }

                // Sorts array by distance from least to greatest
                const sortedZipDistance = mergeSortByFirstAttr(zipDistance);

                // Array to hold sorted coordinates 
                const sortedCoordinates = [];

                for (let i = 0; i < sortedZipDistance.length; i++) {
                    for (let y = 0; y < dealerGeoLocations.length; y++) {
                        if (sortedZipDistance[i].zipcode === dealerGeoLocations[y].zipcode) {
                            sortedCoordinates.push(dealerGeoLocations[y]);
                        }
                    }
                }

                const userMarker = {
                    position: userCoordinates,
                    icon: {
                        url: "https://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                    }
                }

                setDealerList(sortedCoordinates);
                setMapInfo(userMarker);
                setShowMap(true);
                setZipCode('');
            }

        } catch (err) {
            alert("Please Enter a Valid Zip Code");
        }

    }

    return (
        <Box id='map-form'>
            <Typography className='font-toyota text-center text-5xl font-bold m-8' component='h3' sx={{ color: 'white' }}>
                Schedule Your Service Today!
            </Typography>
            <Typography className='font-toyota text-center text-2xl font-medium m-8' component='h3' sx={{ color: 'white' }}>
                Please Enter Your Zip Code Below To Find Your Nearest Toyota Dealer
            </Typography>
            <Card id='find-dealer' className='bg-toyota-red m-8'>
                <CardContent >
                    <form onSubmit={submitHandler}>
                        <Box className='flex flex-auto flex-col'>
                            <TextField
                                className='font-toyota rounded focus:outline-toyota-black'
                                required
                                label="Zip Code"
                                value={zipCode}
                                inputRef={inputRef}
                                onChange={(e) => setZipCode(e.target.value)}
                                sx={{
                                    color: 'black',
                                    backgroundColor: 'white',
                                    '& .MuiOutlinedInput-root': {
                                        '&:hover .MuiOutlinedInput-notchedOutline' : {
                                            borderColor: 'black',
                                        },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'black',
                                        }
                                    },
                                    '& .MuiInputLabel-root.Mui-focused': {
                                        color: 'black',
                                    }
                                }}
                            />
                            <Button type='submit' className='hover:bg-toyota-dark font-toyota' variant='contained' disableElevation sx={{ color: 'white', backgroundColor: 'black' }}>
                                Submit
                            </Button>
                        </Box>
                    </form>
                </CardContent>
            </Card>
        </Box>
    );
}

export default MapForm;