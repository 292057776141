// MUI Components
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

function MapImage({ switchImageForMap }) {
    return (
        <Card className='bg-toyota-red w-full xs:max-w-sm' sx={{ heigth: 800 }}>
            <CardContent className='flex justify-center items-center xs:max-w-sm'>
                <CardMedia
                    onClick={switchImageForMap}
                    className='rounded-xl max-w-3xl cursor-pointer xs:max-h-56 xs:max-w-xs sm:max-w-sm md:max-w-xl'
                    component='img'
                    image='https://email-mrktng-images.s3.amazonaws.com/images/landing-page/Map-Image/Map.png'
                    alt='Map Of Phoenix Valley Toyota Dealers Locations'
                    sx={{ objectFit: 'contain' }}
                />
            </CardContent>
        </Card>
    )
}

export default MapImage;