// MUI Components
import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";

// My Components
import MapForm from './MapForm/MapForm';

// React Hook
import { useState, useEffect } from 'react';

// My Components
import DealerList from './DealerList/DealerList';
import MapArea from './MapArea/MapArea';
import FirstMap from '../FirstMap/FirstMap';
import LocationSB from '../LocationSB/LocationSB';
import FindLocationSB from '../FindLocationSB/FindLocationSB';
import MapImage from './MapImage/MapImage';

// Google Map API
import { useJsApiLoader } from '@react-google-maps/api';

// Sorting Algorithm
import mergeSortByFirstAttr from '../../../Utils/SortingAlgo/SortingAlgo';

// Delear Info
const zipCodes = ['85014', '85023', '85260', '85201', '85323', '85284', '85382', '85388', '85286', '85206'];
const dealerGeoLocations = [
    {
        zipcode: '85014',
        location: { lat: 33.510654246939225, lng: -112.04835957475329 },
        dealerInfo: {
            name: 'Camelback Toyota',
            address: '1550 East Camelback Road Phoenix, AZ 85014',
            phoneStr: '(602) 264-2841',
            phone: 6022642841,
            hours: '7am - 6pm',
            serviceID: 'Camelback-Service',
            serviceLink: 'https://camelbacktoyota.svcapt.com/',
            contactID: 'Camelback-Contact',
            contactLink: 'https://www.camelbacktoyota.com/contact.htm',
            websiteID: 'Camelback-Website',
            websiteLink: 'https://www.camelbacktoyota.com/',
        }
    },
    {
        zipcode: '85023',
        location: { lat: 33.64102565292091, lng: -112.10325814782797 },
        dealerInfo: {
            name: 'Bell Road Toyota',
            address: '2020 West Bell Road Phoenix, AZ 85023',
            phoneStr: '(602) 863-0600',
            phone: 6028630600,
            hours: '7am - 6pm',
            serviceID: 'Bell-Service',
            serviceLink: 'https://www.bellroadtoyota.com/schedule-service-sd',
            contactID: 'Bell-Contact',
            contactLink: 'https://www.bellroadtoyota.com/contact-us',
            websiteID: 'Bell-Website',
            websiteLink: 'https://www.bellroadtoyota.com/',
        }
    },
    {
        zipcode: '85260',
        location: { lat: 33.63470182879427, lng: -111.9143518050807 },
        dealerInfo: {
            name: 'Right Toyota',
            address: '7701 E Frank Lloyd Wright Scottsdale, AZ 85260',
            phoneStr: '(480) 778-2200',
            phone: 4807782200,
            hours: '7am - 6pm',
            serviceID: 'Right-Service',
            serviceLink: 'https://www.righttoyota.com/schedule-service/',
            contactID: 'Right-Contact',
            contactLink: 'https://www.righttoyota.com/contact-us/',
            websiteID: 'Right-Website',
            websiteLink: 'https://www.righttoyota.com/',
        }
    },
    {
        zipcode: '85201',
        location: { lat: 33.435570737135656, lng: -111.87112042346182 },
        dealerInfo: {
            name: 'Berge Toyota',
            address: '2020 W Riverview Auto Dr. Mesa, AZ 85201',
            phoneStr: '(480) 655-4400',
            phone: 4806554400,
            hours: '7am - 6pm',
            serviceID: 'Berge-Service',
            serviceLink: 'https://www.bergetoyota.com/service/',
            contactID: 'Berge-Contact',
            contactLink: 'https://www.bergetoyota.com/contact-us/',
            websiteID: 'Berge-Website',
            websiteLink: 'https://www.bergetoyota.com/',
        }
    },
    {
        zipcode: '85323',
        location: { lat: 33.459838545618894, lng: -112.27620637278451 },
        dealerInfo: {
            name: 'Avondale Toyota',
            address: '10005 West Papago Frwy. Avondale, AZ 85323',
            phoneStr: '(623) 936-7700',
            phone: 6239367700,
            hours: '7am - 6pm',
            serviceID: 'Avondale-Service',
            serviceLink: 'https://consumer.xtime.com/scheduling/?webKey=xtm202207061215xx1&skipRedirect=true&VARIANT=TOYOTAUSA_ENH2&WMODE=true',
            contactID: 'Avondale-Contact',
            contactLink: 'https://www.avondaletoyota.com/contact.htm',
            websiteID: 'Avondale-Website',
            websiteLink: 'https://www.avondaletoyota.com/',
        }
    },
    {
        zipcode: '85284',
        location: { lat: 33.34446285138001, lng: -111.97080085047818 },
        dealerInfo: {
            name: 'AutoNation Toyota Tempe',
            address: '7970 South Autoplex Loop Tempe, AZ 85284',
            phoneStr: '(480) 420-3425',
            phone: 4804203425,
            hours: '7am - 7pm',
            serviceID: 'AutoNatTempe-Service',
            serviceLink: 'https://www.autonationtoyotatempe.com/service/schedule/appointment.htm',
            contactID: 'AutoNatTempe-Contact',
            contactLink: 'https://www.autonationtoyotatempe.com/dealership/about.htm',
            websiteID: 'AutoNatTempe-Website',
            websiteLink: 'https://www.autonationtoyotatempe.com/',
        }
    },
    {
        zipcode: '85382',
        location: { lat: 33.6370750321906, lng: -112.24416577278114 },
        dealerInfo: {
            name: 'Larry H. Miller Toyota Peoria',
            address: '8633 West Bell Road Peoria, AZ 85382',
            phoneStr: '(623) 876-3400',
            phone: 6238763400,
            hours: '7am - 6pm',
            serviceID: 'LHMiller-Service',
            serviceLink: 'https://www.larrymillertoyota.com/service/appointment-form.htm',
            contactID: 'LHMiller-Contact',
            contactLink: 'https://www.larrymillertoyota.com/contact.htm',
            websiteID: 'LHMiller-Website',
            websiteLink: 'https://www.larrymillertoyota.com/',
        }
    },
    {
        zipcode: '85388',
        location: { lat: 33.607475603598935, lng: -112.42066769582502 },
        dealerInfo: {
            name: 'Toyota of Surprise',
            address: '13543 N. Auto Show Avenue Surprise, AZ 85388',
            phoneStr: '(623) 312-3100',
            phone: 6233123100,
            hours: '7am - 6pm',
            serviceID: 'Surprise-Service',
            serviceLink: 'https://www.toyotaofsurprise.com/schedule-service.htm',
            contactID: 'Surprise-Contact',
            contactLink: 'https://www.toyotaofsurprise.com/about-us-page.htm',
            websiteID: 'Surprise-Website',
            websiteLink: 'https://www.toyotaofsurprise.com/',
        }
    },
    {
        zipcode: '85286',
        location: { lat: 33.2881177132745, lng: -111.79095266138216 },
        dealerInfo: {
            name: 'Big Two Toyota of Chandler',
            address: '1250 S. Gilbert Road Chandler, AZ 85286',
            phoneStr: '(480) 898-6000',
            phone: 4808986000,
            hours: '7am - 9pm',
            serviceID: 'Big2Chandler-Service',
            serviceLink: 'https://www.bigtwotoyota.com/service/appointments.htm',
            contactID: 'Big2Chandler-Contact',
            contactLink: 'https://www.bigtwotoyota.com/contact.htm',
            websiteID: 'Big2Chandler-Website',
            websiteLink: 'https://www.bigtwotoyota.com/',
        }
    },
    {
        zipcode: '85206',
        location: { lat: 33.38867233566981, lng: -111.69925348465553 },
        dealerInfo: {
            name: 'Earnhardt Toyota',
            address: '6136 East Autoloop Avenue Mesa, AZ 85206',
            phoneStr: '(480) 807-9700',
            phone: 4808079700,
            hours: '7am - 6pm',
            serviceID: 'Earnhardt-Service',
            serviceLink: 'https://www.earnhardttoyota.com/schedule-car-maintenance-or-auto-repair-mesa-az',
            contactID: 'Earnhardt-Contact',
            contactLink: 'https://www.earnhardttoyota.com/contactus.aspx',
            websiteID: 'Earnhardt-Website',
            websiteLink: 'https://www.earnhardttoyota.com/',
        }
    }
];

const libraries = ['places', 'routes', 'geocoding'];

function MapContainer() {
    const [showMap, setShowMap] = useState(false);
    const [showImage, setShowImage] = useState(true);
    const [dealerList, setDealerList] = useState([]);
    const [mapInfo, setMapInfo] = useState({});
    const [zipCode, setZipCode] = useState('');
    const [openError, setOpenError] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [successTxt, setSuccessTxt] = useState('Finding Your Nearest Valley Toyota Dealer!');
    const [successVariant, setSuccessVariant] = useState('info');
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyCq8oxvUWpMt8ojlaH_uH9cLS7dzPqF0T4',
        libraries,
    })

    const handleErrorOpen = () => {
        setOpenError(true);
    };

    const handleErrorClose = () => {
        setOpenError(false);
    };

    const handleSuccessOpen = () => {
        setOpenSuccess(true);
    }

    const handleSuccessClose = () => {
        setOpenSuccess(false);
    }

    const switchImageForMap = () => {
        setShowImage(false);
    }

    useEffect(() => {
        function getGeoLocation() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    async (position) => {
                        handleSuccessOpen();

                        const location = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        }

                        try {

                            // instantiate Distance Matrix service
                            const service = new window.google.maps.DistanceMatrixService();

                            const matrixOptions = {
                                // dealer locations
                                origins: [location],
                                // customer address
                                destinations: zipCodes,
                                travelMode: 'DRIVING',
                                unitSystem: window.google.maps.UnitSystem.IMPERIAL
                            }

                            // Call Distance Matrix service
                            service.getDistanceMatrix(matrixOptions, callback);

                            // Callback function used to process Distance Matrix response
                            function callback(response, status) {
                                if (status !== "OK") {
                                    return;
                                }

                                // Pulls distance data from api response
                                const distanceObjects = response.rows[0].elements;

                                // Grabs the distances in miles from response and splits number from the units and maps into new array
                                const distances = distanceObjects.map((dataObject, i) => { return dataObject.distance.text.split(' ') });

                                // Empty array to hold unordered list of distance from user to dealer
                                const unorderedList = [];
                                // Empty array to hold object containing distance to dealership and zipcode
                                const zipDistance = [];

                                for (let i = 0; i < distances.length; i++) {
                                    // Adds numeric value for distance to unsorted array
                                    unorderedList.push(parseInt(distances[i][0]));
                                }

                                for (let i = 0; i < zipCodes.length; i++) {
                                    // adds distance and zipcode object into array
                                    zipDistance.push({ distance: unorderedList[i], zipcode: zipCodes[i] });
                                }

                                // Sorts array by distance from least to greatest
                                const sortedZipDistance = mergeSortByFirstAttr(zipDistance);

                                // Array to hold sorted coordinates 
                                const sortedCoordinates = [];

                                for (let i = 0; i < sortedZipDistance.length; i++) {
                                    for (let y = 0; y < dealerGeoLocations.length; y++) {
                                        if (sortedZipDistance[i].zipcode === dealerGeoLocations[y].zipcode) {
                                            sortedCoordinates.push(dealerGeoLocations[y]);
                                        }
                                    }
                                }

                                const userMarker = {
                                    position: location,
                                    icon: {
                                        url: "https://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                                    }
                                }

                                setSuccessVariant('success');
                                setSuccessTxt('Found Your Nearest Valley Toyota Dealer!');
                                setDealerList(sortedCoordinates);
                                setMapInfo(userMarker);
                                setShowMap(true);
                            }

                        } catch (err) {
                            setSuccessVariant('warning');
                            setSuccessTxt('Unable to find your location. Please try refreshing page or enter location below.');
                        }
                    },
                    (error) => {
                        handleErrorOpen();
                    }
                );
            }
        }

        if (isLoaded) {
            getGeoLocation();
        }

    }, [isLoaded]);

    if (isLoaded) {
        return (
            <>
                <Box className='flex justify-center items-center flex-col '>
                    <MapForm zipCode={zipCode} setZipCode={setZipCode} setShowMap={setShowMap} setDealerList={setDealerList} setMapInfo={setMapInfo} zipCodes={zipCodes} dealerGeoLocations={dealerGeoLocations} />
                    {showMap ? <Grid container spacing={1}>
                        <Grid item xs={12} sm={6} md={6} sx={{ order: { xs: 1, sm: 0, md: 0 } }}>
                            <DealerList dealerList={dealerList} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} sx={{ order: { xs: -1, sm: 0, md: 0 } }}>
                            <MapArea mapInfo={mapInfo} dealerList={dealerList} />
                        </Grid>
                    </Grid> : <>
                        {showImage ? <MapImage switchImageForMap={switchImageForMap} /> : <FirstMap dealerGeoLocations={dealerGeoLocations} />}
                    </>}
                </Box>
                <LocationSB openError={openError} handleErrorClose={handleErrorClose} />
                <FindLocationSB openSuccess={openSuccess} handleSuccessClose={handleSuccessClose} successTxt={successTxt} successVariant={successVariant} />
            </>
        );
    } else {
        return (<div></div>);
    }
}

export default MapContainer;