// MUI Components
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

function LocationSB({ openError, handleErrorClose }) {

    return (
        <Snackbar open={openError} anchorOrigin={{ horizontal: 'right', vertical: 'top' }} autoHideDuration={6000} onClose={handleErrorClose}>
            <Alert className='font-toyota text-sm' onClose={handleErrorClose} variant="filled" severity="error" sx={{ width: '100%' }}>
                Enable Location Services To Automatically Find Your Nearest Valley Toyota Dealer
            </Alert>
        </Snackbar>
    );
}

export default LocationSB