// MUI Components
import Box from "@mui/material/Box";

// My Components
import HeaderContainer from "./Components/Header/HeaderContainer";
import MainContainer from "./Components/Main/MainContainer";

function App() {
  return (
    <Box className='flex flex-col justify-center content-center w-full h-full' >
      <HeaderContainer />
      <MainContainer />
    </Box>
  );
}

export default App;
