// MUI Components
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

function LocationSB({ openSuccess, handleSuccessClose, successTxt, successVariant }) {

    return (
        <Snackbar open={openSuccess} anchorOrigin={{ horizontal: 'right', vertical: 'top' }} autoHideDuration={6000} onClose={handleSuccessClose}>
            <Alert className='font-toyota text-sm' onClose={handleSuccessClose} variant="filled" severity={successVariant} sx={{ width: '100%' }}>
                {successTxt}
            </Alert>
        </Snackbar>
    );
}

export default LocationSB