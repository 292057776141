// MUI Components
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

// MUI Icons
import CircleIcon from '@mui/icons-material/Circle';

function AboutVTD() {
    return (
        <Box className='m-0 p-0 w-full'>
            <Typography className='font-toyota text-center text-5xl font-semibold mb-24 mt-12 mx-52 xs:mb-8 xs:text-lg xs:mx-5' component='h3' sx={{ color: 'white' }}>
                When you service with a local Valley Toyota Dealer, both you and your vehicle can put trust in the quality of our guest experience
            </Typography>
            <Card className='mx-8 mb-96 xs:mb-9' sx={{ padding: 0, bgcolor: 'transparent' }}>
                <CardContent className='flex justify-center flex-wrap-reverse'>
                    <Box className='flex flex-col justify-center items-center p-0 lg:mr-28 xl:mr-28' >
                        <Typography className='font-toyota text-center text-5xl font-bold mb-8 xs:text-4xl' component='h3' sx={{ color: 'white' }}>
                            Our Service Center Offers:
                        </Typography>
                        <Divider flexItem sx={{ color: 'white' }} />
                        <List className='p-0 m-0'>
                            <ListItem className='font-toyota text-2xl font-medium' sx={{ color: 'white' }}>
                                <ListItemIcon>
                                    <CircleIcon sx={{ color: '#eb091e' }} />
                                </ListItemIcon>
                                Factory trained technicians
                            </ListItem>
                            <ListItem className='font-toyota text-2xl font-medium' sx={{ color: 'white' }}>
                                <ListItemIcon>
                                    <CircleIcon sx={{ color: '#eb091e' }} />
                                </ListItemIcon>
                                Genuine Toyota Parts
                            </ListItem>
                            <ListItem className='font-toyota text-2xl font-medium' sx={{ color: 'white' }}>
                                <ListItemIcon>
                                    <CircleIcon sx={{ color: '#eb091e' }} />
                                </ListItemIcon>
                                State-of-the-Art Facilities
                            </ListItem>
                            <ListItem className='font-toyota text-2xl font-medium' sx={{ color: 'white' }}>
                                <ListItemIcon>
                                    <CircleIcon sx={{ color: '#eb091e' }} />
                                </ListItemIcon>
                                Latest Tools & Equipment
                            </ListItem>
                            <ListItem className='font-toyota text-2xl font-medium' sx={{ color: 'white' }}>
                                <ListItemIcon>
                                    <CircleIcon sx={{ color: '#eb091e' }} />
                                </ListItemIcon>
                                Comfortable Lounge
                            </ListItem>
                            <ListItem className='font-toyota text-2xl font-medium' sx={{ color: 'white' }}>
                                <ListItemIcon>
                                    <CircleIcon sx={{ color: '#eb091e' }} />
                                </ListItemIcon>
                                And Much More!
                            </ListItem>
                        </List>
                    </Box>
                    <CardMedia
                        component="img"
                        src='https://email-mrktng-images.s3.amazonaws.com/images/landing-page/2024/April/Technicians.jpg'
                        className='p-0 max-w-xl rounded-xl object-contain w-fit xs:max-w-xs xs:mb-10 xs:max-h-80'
                        sx={{ maxHeight: 600 }}
                        alt="Toyota Technician Showing Off Available Amenities At A Valley Toyota Dealer Service Center"
                    />
                </CardContent>
            </Card>
        </Box>
    );
}

export default AboutVTD;