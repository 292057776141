// MUI Components
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

// Google Map API
import { GoogleMap, Marker } from '@react-google-maps/api';

function MapArea({ mapInfo, dealerList }) {
    const zoom = 8.7;
    const center = {
        lat: 33.4484,
        lng: -112.0740
    }
    const mapStyle = {
        width: '100%',
        height: '400px'
    }

    return (
        <Card className="bg-toyota-red xs:mb-1 sm:mb-1" sx={{ height: 800, maxHeight: 440 }}>
            <CardContent className="w-full">
                <GoogleMap
                    mapContainerClassName="w-full"
                    mapContainerStyle={mapStyle}
                    center={center}
                    zoom={zoom}
                >
                    <Marker icon={mapInfo.icon} position={mapInfo.position} />
                    {dealerList.map((dealer, index) => {
                        return (
                            <Marker key={index} label={`${index + 1}`} position={dealer.location} />
                        );
                    })}
                </GoogleMap>
            </CardContent>
        </Card>
    );
}

export default MapArea;